
import Vue, { PropType } from "vue";
import ContentItem, {
  CarouselItem,
} from "~/components/UI/carousel/ContentCarousel/ContentItem.vue";
import { ImageHeight } from "~/components/shared/responsive-image/ResponsiveImage.vue";

export interface ItemsPerRow {
  desktop: number;
  tablet: number;
  mobile: number;
}

export default Vue.extend({
  name: "MultiColumnContent",
  components: { ContentItem },
  props: {
    items: {
      type: Array as PropType<CarouselItem[]>,
      required: true,
    },
    showReadMore: {
      type: Boolean as PropType<Boolean>,
      required: false,
      default: false,
    },
    itemsPerRow: {
      type: Object as PropType<ItemsPerRow>,
      required: false,
      default: () => ({ desktop: 3, tablet: 2, mobile: 1 }),
    },
    imageHeight: {
      type: Object as PropType<ImageHeight>,
      required: false,
      default: () => {},
    },
    contentColour: {
      type: String as PropType<String>,
      required: false,
      default: "#1a202c",
    },
    bodyColour: {
      type: String as PropType<String>,
      required: false,
      default: "#252525",
    },
    backgroundColour: {
      type: String as PropType<String>,
      required: false,
      default: "",
    },
    borderColour: {
      type: String as PropType<String>,
      required: false,
      default: "",
    },
    ctaBtnColour: {
      type: String as PropType<String>,
      required: false,
      default: "",
    },
    centerItems: {
      type: Boolean as PropType<Boolean>,
      required: false,
      default: false,
    },
    imageIsIcon: {
      type: Boolean as PropType<Boolean>,
      required: false,
      default: false,
    },
  },
  computed: {
    gridTemplateColumns(): string {
      return `${this.getColumnClass(
        this.itemsPerRow.desktop
      )} ${this.getColumnClass(
        this.itemsPerRow.tablet
      )}--tablet ${this.getColumnClass(this.itemsPerRow.mobile)}--mobile`;
    },
  },
  methods: {
    getColumnClass(val: number) {
      switch (val) {
        case 1:
          return "grid-template-columns-one";
        case 2:
          return "grid-template-columns-two";
        case 3:
          return "grid-template-columns-three";
        case 4:
          return "grid-template-columns-four";
        case 5:
          return "grid-template-columns-five";
        case 6:
          return "grid-template-columns-six";
        default:
          return "grid-template-columns-three";
      }
    },
  },
});
