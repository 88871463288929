import { render, staticRenderFns } from "./versionTwo.vue?vue&type=template&id=51e6da55&scoped=true"
import script from "./versionTwo.vue?vue&type=script&setup=true&lang=js"
export * from "./versionTwo.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51e6da55",
  null
  
)

export default component.exports