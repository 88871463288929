import { computed, ComputedRef } from "vue";
import {
  ComponentParameters,
  GlobalComponentProperties,
} from "./globalComponentProperties.d";
import { transform } from "~/lib/tailwind";
import "@bloomreach/spa-sdk";

export const globalComponentProperties = (
  properties: ComputedRef<ComponentParameters>,
  page = null as any
): GlobalComponentProperties => {
  const wrapperClasses = computed(() => {
    const classes = [];
    const styles = [];
    if (properties.value?.noBottomPadding) classes.push("!pb-0");
    if (properties.value?.noTopPadding) classes.push("!pt-0");
    if (properties.value?.reducedTopPadding) classes.push("!pt-10");
    if (properties.value?.reducedBottomPadding) classes.push("!pb-10");
    styles.push(bgColour.value);
    return { classes: classes.join(" "), styles: styles.join(" ") };
  });

  const innerWrapperClasses = computed(() => {
    const classes = [];
    const styles = [];
    if (properties.value?.reducedWidth)
      classes.push("px-4 md:px-[12rem] lg:px-[16rem]");
    if (bgColour.value !== innerBgColour.value) classes.push("p-8 shadow");
    styles.push(innerBgColour.value);
    return { classes: classes.join(" "), styles: styles.join(" ") };
  });

  // Take properties that should be transformed to a tailwind class
  // e.g. "object fit" as "cover" in human terms, should become object-cover in tailwind terms
  const tailwindTransformers = computed(() => transform(properties.value));

  const { primaryColour, secondaryColour, tertiaryColour } = page?.getDocument()
    .model.data ?? {
    primaryColour: "#ffff",
    secondaryColour: "#ffff",
    tertiaryColour: "#ffff",
  };

  const innerBgColour = computed(() => {
    switch (properties.value?.innerBackgroundColour) {
      case "white":
        return `background: #ffff`;
      case "light grey":
        return `background: #f4f4f4`;
      case "dark":
        return "background: #1a202c";
      case "blue":
        return "background: #065CAB";
      case "primary colour":
        return `background: ${primaryColour || "#ffff"}`;
      case "secondary colour":
        return `background: ${secondaryColour || "#ffff"}`;
      case "tertiary colour":
        return `background: ${tertiaryColour || "#ffff"}`;
      default:
        return "";
    }
  });

  const bgColour = computed(() => {
    switch (properties.value?.backgroundColour) {
      case "white":
        return "background: #ffff";
      case "light grey":
        return "background: #f4f4f4";
      case "dark":
        return "background: #1a202c";
      case "blue":
        return "background: #065CAB";
      case "primary colour":
        return `background: ${primaryColour || "#ffff"}`;
      case "secondary colour":
        return `background: ${secondaryColour || "#ffff"}`;
      case "tertiary colour":
        return `background: ${tertiaryColour || "#ffff"}`;
      default:
        return "";
    }
  });

  const itemBackgroundColour = computed(() => {
    switch (properties.value?.itemBackgroundColour) {
      case "none":
        return "";
      case "blue":
        return "#065CAB";
      case "white":
        return "#fff";
      case "dark":
        return "#1a202c";
      case "primary colour":
        return `${primaryColour}`;
      case "secondary colour":
        return `${secondaryColour}`;
      case "tertiary colour":
        return `${tertiaryColour}`;
      default:
        return "#1a202c";
    }
  });

  const titleAlignment = computed(() => {
    switch (properties.value?.titleAlignment) {
      case "left":
        return "text-left";
      case "center":
        return "text-center";
      case "right":
        return "text-end";
      default:
        return "";
    }
  });

  const bodyAlignment = computed(() => {
    switch (properties.value?.bodyAlignment) {
      case "left":
        return "text-left";
      case "center":
        return "text-center";
      case "right":
        return "text-end";
      default:
        return "";
    }
  });

  const imageAlignment = computed(() => {
    switch (properties.value?.imageAlignment) {
      case "left":
        return "mr-auto";
      case "center":
        return "mx-auto";
      case "right":
        return "ml-auto";
      default:
        return "";
    }
  });

  const contentAlignment = computed(() => {
    switch (properties.value?.contentAlignment) {
      case "top left":
        return { parent: "justify-start", children: "justify-start text-left" };
      case "bottom left":
        return { parent: "justify-start", children: "justify-end text-left" };
      case "top right":
        return { parent: "justify-end", children: "justify-start text-left" };
      case "bottom right":
        return { parent: "justify-end", children: "justify-end text-left" };
      case "top center":
        return {
          parent: "justify-center",
          children: "justify-start text-left",
        };
      case "bottom center":
        return { parent: "justify-center", children: "justify-end text-left" };
      case "center":
        return {
          parent: "justify-center",
          children: "justify-center items-center text-center",
        };
      default:
        return { parent: "", children: "" };
    }
  });

  const titleTextColour = computed(() => {
    switch (properties.value?.titleTextColour) {
      case "blue":
        return "color: #065CAB";
      case "white":
        return "color: #fff";
      case "dark":
        return "color: #1a202c";
      case "grey 05":
        return "color: #666666";
      case "red":
        return "color: #E52228";
      case "orange":
        return "color: #BD5B00";
      case "green":
        return "color: #238826";
      case "yellow":
        return "color: #FEE200";
      case "primary colour":
        return `color: ${primaryColour}`;
      case "secondary colour":
        return `color: ${secondaryColour}`;
      case "tertiary colour":
        return `color: ${tertiaryColour}`;
      default:
        return "";
    }
  });

  const bodyTextColour = computed(() => {
    switch (properties.value?.bodyTextColour) {
      case "blue":
        return "color: #065CAB";
      case "white":
        return "color: #fff";
      case "dark":
        return "color: #1a202c";
      case "primary colour":
        return `color: ${primaryColour}`;
      case "secondary colour":
        return `color: ${secondaryColour}`;
      case "tertiary colour":
        return `color: ${tertiaryColour}`;
      default:
        return "color: #1a202c";
    }
  });

  const contentColour = computed(() => {
    switch (properties.value?.contentColour) {
      case "blue":
        return "#065CAB";
      case "white":
        return "#fff";
      case "dark":
        return "#1a202c";
      case "primary colour":
        return `${primaryColour}`;
      case "secondary colour":
        return `${secondaryColour}`;
      case "tertiary colour":
        return `${tertiaryColour}`;
      default:
        return "#1a202c";
    }
  });

  const buttonColour = computed(() => {
    switch (properties.value?.buttonColour) {
      case "blue":
        return `background: #065CAB`;
      case "white":
        return `background: #fff; color: ${primaryColour}`;
      case "primary colour":
        return `background:${primaryColour}`;
      case "secondary colour":
        return `background:${secondaryColour}`;
      case "tertiary colour":
        return `background:${tertiaryColour}`;
      default:
        return `background: #065CAB`;
    }
  });

  const ctaBtnColour = computed(() => {
    switch (properties.value?.ctaBtnColour) {
      case "blue":
        return `background: #065CAB`;
      case "white":
        return "background: #fff; color: #1a202c";
      case "dark":
        return "background: #1a202c";
      case "primary colour":
        return `background:${primaryColour}`;
      case "secondary colour":
        return `background:${secondaryColour}`;
      case "tertiary colour":
        return `background:${tertiaryColour}`;
      default:
        return `background: ${contentColour.value}`;
    }
  });

  const borderColour = computed(() => {
    switch (properties.value?.borderColour) {
      case "blue":
        return "border: 1px solid #065CAB";
      case "white":
        return "border: 1px solid #fff";
      case "dark":
        return "border: 1px solid #1a202c";
      case "grey":
        return "border: 1px solid #D9D9D9";
      case "primary colour":
        return `border: 1px solid ${primaryColour}`;
      case "secondary colour":
        return `border: 1px solid ${secondaryColour}`;
      case "tertiary colour":
        return `border: 1px solid ${tertiaryColour}`;
      default:
        return "";
    }
  });

  const bulletColour = computed(() => {
    switch (properties.value?.bulletColour) {
      case "blue":
        return "background: #065CAB";
      case "white":
        return "background: #fff";
      case "dark":
        return "background: #1a202c";
      case "primary colour":
        return `background: ${primaryColour}`;
      case "secondary colour":
        return `background:${secondaryColour}`;
      case "tertiary colour":
        return `background: ${tertiaryColour}`;
      default:
        return "background: #065CAB";
    }
  });

  const height = computed(() => {
    switch (properties.value?.height) {
      case "small":
        return "h-[125px] md:h-[125px]";
      case "medium":
        return "h-[250px] md:h-[300px]";
      case "large":
        return "h-[250px] md:h-[500px]";
      default:
        return "";
    }
  });

  const displayContent = computed(() => {
    switch (properties.value?.displayContent) {
      case "all":
        return "";
      case "desktop":
        return "hidden lg:flex";
      case "tablet and desktop":
        return "hidden md:flex";
      case "mobile and tablet":
        return "lg:hidden";
      case "mobile":
        return "sm:hidden";
      default:
        return "";
    }
  });

  const headingType = computed(() => properties.value?.titleTag);

  return {
    wrapperClasses,
    innerWrapperClasses,
    tailwindTransformers,
    titleAlignment,
    bodyAlignment,
    contentAlignment,
    imageAlignment,
    bgColour,
    itemBackgroundColour,
    headingType,
    titleTextColour,
    bodyTextColour,
    contentColour,
    buttonColour,
    height,
    displayContent,
    borderColour,
    primaryColour,
    secondaryColour,
    tertiaryColour,
    ctaBtnColour,
    innerBgColour,
    bulletColour,
  };
};
