
import Vue, { PropType } from "vue";
import DefaultButton from "~/components/UI/button/DefaultButton.vue";
import ResponsiveImage, {
  ImageHeight,
  Images,
} from "~/components/shared/responsive-image/ResponsiveImage.vue";

export interface CarouselItem {
  images: Images;
  title: string;
  body: string;
  cta: {
    href: string;
    text: string;
  };
}

export default Vue.extend({
  name: "ContentCarouselItem",
  components: { DefaultButton, ResponsiveImage },
  props: {
    item: {
      type: Object as PropType<CarouselItem>,
      required: true,
    },
    showReadMore: {
      type: Boolean as PropType<Boolean>,
      required: false,
      default: false,
    },
    imageHeight: {
      type: Object as PropType<ImageHeight>,
      required: false,
      default: () => ({}),
    },
    contentColour: {
      type: String as PropType<String>,
      required: false,
      default: "#1a202c",
    },
    bodyColour: {
      type: String as PropType<String>,
      required: false,
      default: "#252525",
    },
    backgroundColour: {
      type: String as PropType<String>,
      required: false,
      default: "",
    },
    borderColour: {
      type: String as PropType<String>,
      required: false,
      default: "",
    },
    centerItems: {
      type: Boolean as PropType<Boolean>,
      required: false,
      default: false,
    },
    imageIsIcon: {
      type: Boolean as PropType<Boolean>,
      required: false,
      default: false,
    },
    ctaBtnColour: {
      type: String as PropType<String>,
      required: false,
      default: "",
    },
  },
  data(): {
    showFullBody: Boolean;
  } {
    return {
      showFullBody: false,
    };
  },
  computed: {
    getBody(): string {
      return this.showFullBody ||
        !this.showReadMore ||
        this.item.body.length <= 120
        ? this.item.body
        : `${this.item.body.substring(0, 120)}...`;
    },
  },
  methods: {
    toggleReadMore() {
      this.showFullBody = !this.showFullBody;
    },
  },
});
