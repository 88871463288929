<template>
  <button
    class="rounded-full h-[35px] cursor-pointer my-auto border-blue border-1 px-3 py-2 mx-1 flex items-center justify-center hover:bg-blue hover:text-white ease-in-out duration-200 text-size-4 whitespace-nowrap"
    :class="active ? 'bg-blue text-white' : 'bg-white text-blue'"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "BaseLozenge",
  props: {
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
