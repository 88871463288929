<template>
  <div
    v-if="data"
    class="w-full relative py-16 sm:py-23"
    :class="wrapperClasses.classes"
    :style="wrapperClasses.styles"
  >
    <div class="content-container">
      <ProductRecommendations
        v-if="recommendations !== undefined"
        :data="recommendations"
        :style-type="style"
        :title="data.title"
        :slides-per-page="slidesPerPage"
        :show-add-to-trolley="showCTAs"
        :show-badges="showBadges"
        :show-flash="showFlash"
        :display-as-grid="displayAsGrid"
        :is-loading="$fetchState.pending"
      />
    </div>
  </div>
</template>
<script>
import { getContainerItemContent } from "@bloomreach/spa-sdk";
import { mapGetters } from "vuex";
import { computed } from "vue";
import { getRecommendations } from "~/services/search.service";
import ProductRecommendations from "~/components/product-recommendations/ProductRecommendations.vue";
import { globalComponentProperties } from "~/composables/globalComponentProperties";
import {
  getWidgetType,
  getWidgetParams,
} from "~/utils/bloomreach/pathwaysAndRecs";
export default {
  components: { ProductRecommendations },
  props: {
    component: {
      type: Object,
      required: true,
    },
    page: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // Return component properties from bloomreach SDK
    const properties = computed(() => props.component.getParameters());
    // Deconstruct computed style properties from composable
    const { wrapperClasses } = globalComponentProperties(
      properties,
      props.page
    );
    return {
      properties,
      wrapperClasses,
    };
  },
  data() {
    return {
      recommendations: {},
    };
  },
  async fetch() {
    await this.getData(
      this,
      this.widgetType || "category",
      this.widgetId || "1jwnm3jz",
      this.widgetParams || { categoryId: "c657" }
    );
  },
  fetchOnServer: false,
  computed: {
    ...mapGetters("productPage", ["getProduct"]),
    taxonomyIndex() {
      return this.getProduct?.taxonomies[0].length;
    },
    data() {
      return getContainerItemContent(this.component, this.page) ?? null;
    },
    style() {
      return this.properties?.style ?? "version 1";
    },
    showCTAs() {
      return this.properties?.showCTAs ?? false;
    },
    showBadges() {
      return this.properties?.showBadges ?? false;
    },
    showFlash() {
      return this.properties?.showSavingFlash ?? false;
    },
    displayAsGrid() {
      return this.properties?.displayAsGrid ?? false;
    },
    slidesPerPage() {
      return {
        desktop: parseInt(this.properties.slidesPerPageDesktop, 10),
        tablet: parseInt(this.properties.slidesPerPageTablet, 10),
        mobile: parseInt(this.properties.slidesPerPageMobile, 10),
      };
    },
    widgetAlgo() {
      return this.data.widgetCompound.widgetType.selectionValues[0].label;
    },
    widgetType() {
      return getWidgetType(this.widgetAlgo);
    },
    widgetId() {
      return this.data?.widgetCompound?.widgetid;
    },
    widgetParams() {
      return getWidgetParams(
        this.widgetType,
        this.categoryId,
        this.productIds,
        this.keyword,
        this.productImpressions()
      );
    },
    keyword() {
      return this.data.keyword;
    },
    productIds() {
      if (this.data.currentProduct) return this.getProduct?.code ?? "";
      return this.data.productCompound
        .map((product) => product.split(";")[0])
        .map((product) => product.split("=")[1])
        .join(",")
        .trim();
    },
    categoryId() {
      return this.data.categoryCompound.categoryid;
    },
  },
  methods: {
    async getData(context, type, id, params) {
      // Fallback widget if no product impressions are found in browser storage
      if (!params?.itemIds && this.data.recentProductImpressions) {
        type = "category";
        id = "1jwnm3jz";
        params = { categoryId: "c1458" };
      }
      this.recommendations = await getRecommendations(
        context,
        type,
        id,
        params
      );
    },
    productImpressions() {
      const productImpressions = this.$cookies.get("productImpressions");
      return productImpressions ? productImpressions.join() : "";
    },
  },
};
</script>
