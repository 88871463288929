<template>
  <div>
    <div v-if="!isCarousel" class="flex gap-x-2 gap-y-4 flex-wrap">
      <component
        :is="getLinkType()"
        v-for="(link, index) in links"
        :key="index"
        v-bind="!isAnchorLinks ? { to: link.href } : {}"
        @click="scrollToAnchor(link.href)"
      >
        <BaseLozenge>{{ link.name }}</BaseLozenge>
      </component>
    </div>

    <BaseCarousel v-else :settings="carouselSetting" class="">
      <template #slides>
        <component
          :is="getLinkType()"
          v-for="(link, index) in links"
          :key="index"
          v-bind="!isAnchorLinks ? { to: link.href } : {}"
          @click="scrollToAnchor(link.href)"
        >
          <BaseLozenge>{{ link.name }}</BaseLozenge>
        </component>
      </template>
    </BaseCarousel>
  </div>
</template>

<script>
import Vue from "vue";
import BaseLozenge from "~/components/UI/button/BaseLozenge.vue";
import BaseCarousel from "~/components/UI/carousel/BaseCarousel.vue";

export default Vue.extend({
  name: "AnchorLinks",
  components: { BaseLozenge, BaseCarousel },
  props: {
    links: {
      type: Array,
      required: true,
    },
    isCarousel: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAnchorLinks: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      carouselSetting: {
        showDots: false,
        showArrows: false,
        slidesPerPage: null,
        gutter: "0.5rem",
      },
    };
  },
  methods: {
    getLinkType() {
      return this.isAnchorLinks ? "div" : "nuxt-link";
    },
    scrollToAnchor(anchor) {
      if (!this.isAnchorLinks) return;
      this.$router.push({ hash: `#${anchor}` });
    },
  },
});
</script>
