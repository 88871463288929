// We are wanting to depreciate this component in favour of 'TextAndMedia' -
waiting for digital to give the all clear.
<template>
  <div
    v-show="data"
    class="w-full relative py-14 sm:py-23"
    :class="[wrapperClasses.classes, displayContent]"
    :style="wrapperClasses.styles"
  >
    <div
      class="w-full h-full flex flex-col justify-center text-left content-container font-gilroy"
      :class="innerWrapperClasses.classes"
      :style="innerWrapperClasses.styles"
    >
      <BaseCarousel
        v-if="isCarousel"
        :settings="carouselSetting"
        :white-dots="whiteCarouselDots"
      >
        <template #slides>
          <Component
            :is="getVariant()"
            v-for="(item, index) in data"
            :key="index"
            :images="getDeviceImages(item.image)"
            :title="item.title"
            :body="item.body.value"
            :cta="item.cta"
            :show-read-more="item.showReadMoreLess"
            :heading-type="headingType"
            :title-colour="titleTextColour"
            :body-colour="bodyTextColour"
            :image-position="imagePosition"
            :title-alignment="titleAlignment"
            :body-alignment="bodyAlignment"
            :image-fit="imageFit"
            :button-colour="buttonColour"
          ></Component>
        </template>
      </BaseCarousel>

      <div
        v-if="!isCarousel"
        class="flex flex-col lg:flex-row gap-16 flex-wrap"
        :class="{ '!flex-row': imageFit === 'contain' }"
      >
        <Component
          :is="getVariant()"
          v-for="(item, index) in data"
          :key="index"
          class="border-1 border-transparent"
          :class="{
            'sm:!max-w-[calc(50%-3rem)] md:!max-w-[calc(33%-4rem)] xl:!max-w-[calc(25%-5rem)]':
              itemCount > 4 && imageFit === 'contain',
            'p-6': borderColour,
          }"
          :style="borderColour ? borderColour : null"
          :images="getDeviceImages(item.image)"
          :title="item.title"
          :body="item.body.value"
          :cta="item.cta"
          :show-read-more="item.showReadMoreLess"
          :heading-type="headingType"
          :title-colour="titleTextColour"
          :body-colour="bodyTextColour"
          :image-position="imagePosition"
          :title-alignment="titleAlignment"
          :body-alignment="bodyAlignment"
          :button-colour="buttonColour"
          :image-fit="imageFit"
        ></Component>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { getContainerItemContent } from "@bloomreach/spa-sdk";
import { globalComponentProperties } from "~/composables/globalComponentProperties";
import BaseCarousel from "~/components/UI/carousel/BaseCarousel.vue";
import { getDeviceImages } from "~/utils/bloomreach/images";

// Variants
import VersionOne from "~/components/bloomreach/title-paragraph-image-button/variants/VersionOne.vue";
import VersionTwo from "~/components/bloomreach/title-paragraph-image-button/variants/VersionTwo";
import VersionThree from "~/components/bloomreach/title-paragraph-image-button/variants/VersionThree";
import VersionFour from "~/components/bloomreach/title-paragraph-image-button/variants/VersionFour";

const props = defineProps({
  component: {
    type: Object,
    required: true,
  },
  page: {
    type: Object,
    required: true,
  },
});

// Return component data fields from bloomreach SDK
const data = computed(
  () =>
    getContainerItemContent(props.component, props.page)?.item ?? [
      getContainerItemContent(props.component, props.page),
    ] ??
    null
);

// Return component properties from bloomreach SDK
const properties = computed(() => props.component.getParameters());

// Deconstruct computed style properties from composable
const {
  wrapperClasses,
  innerWrapperClasses,
  headingType,
  titleTextColour,
  bodyTextColour,
  buttonColour,
  displayContent,
  borderColour,
} = globalComponentProperties(properties, props.page);

const style = computed(() => {
  if (isCarousel.value) return "version 4"; // Uses 'VersionFour' component - used only when it's in carousel mode.
  if (data.value.length > 1) return "version 3"; // Uses 'VersionThree' component - used only when multi items are present and not in carousel mode.
  return properties.value.style;
});

const whiteCarouselDots = computed(() => {
  const conditions = ["white", "light grey"];
  return !conditions.includes(properties.value?.innerBackgroundColour);
});

const imagePosition = computed(() => {
  if (style.value === "version 1") {
    switch (properties.value.imagePosition) {
      case "bottom":
        return {
          classes: "flex-col-reverse",
          position: "bottom",
        };
      default:
        return {
          classes: "",
          position: "top",
        };
    }
  }
  if (style.value === "version 2") {
    switch (properties.value.imagePosition) {
      case "left":
        return {
          classes: "flex-col-reverse md:flex-row-reverse",
          position: "left",
        };
      case "right":
        return {
          classes: "flex-col-reverse md:flex-row",
          position: "right",
        };
      case "top":
        return {
          classes: "",
          position: "top",
        };
      case "bottom":
        return {
          classes: "flex-col-reverse",
          position: "bottom",
        };
      default:
        return {
          classes: "",
          position: "top",
        };
    }
  }
  return {
    classes: "",
    position: "top",
  };
});

const imageFit = computed(() => {
  return properties.value.imageFit;
});

const titleAlignment = computed(() => {
  const { titleAlignment, titleAlignmentMobile } = properties.value;

  return (
    (titleAlignment === "center" ? "md:text-center" : "md:text-left") +
    " " +
    (titleAlignmentMobile === "center" ? "text-center" : "text-left")
  );
});

const bodyAlignment = computed(() => {
  return properties.value.bodyAlignment === "center"
    ? "text-left md:text-center"
    : "text-left";
});

const isCarousel = computed(() => {
  return properties.value.isCarousel;
});

const slidesPerPage = computed(() => {
  return parseInt(properties.value.slidesPerPage, 10);
});

const itemCount = computed(() => {
  return data.value.length;
});

function getVariant() {
  switch (style.value) {
    case "version 1":
      return VersionOne;
    case "version 2":
      return VersionTwo;
    case "version 3":
      return VersionThree;
    case "version 4":
      return VersionFour;
    default:
      return VersionOne;
  }
}

const carouselSetting = {
  showDots: true,
  autoplayDelay: itemCount.value > 4 ? 10 : 0,
  slidesPerPage: slidesPerPage.value,
  paginateBySlide: true,
  showArrows: true,
  gutter: "6rem",
  loop: true,
  responsive: [
    {
      maxWidth: 1024,
      slidesPerPage:
        slidesPerPage.value === 1
          ? 1
          : itemCount.value > 3
            ? 3
            : itemCount.value,
    },
    {
      maxWidth: 768,
      slidesPerPage:
        slidesPerPage.value === 1
          ? 1
          : itemCount.value > 3
            ? 3
            : itemCount.value,
    },
    {
      maxWidth: 640,
      slidesPerPage: 1,
    },
  ],
};
</script>

<style lang="postcss" scoped>
::v-deep(h1),
::v-deep(h2),
::v-deep(h3),
::v-deep(h4) {
  @apply text-blue font-bold;
}

::v-deep(h1) {
  @apply text-size-9;
}
::v-deep(h2) {
  @apply text-size-7.5 sm:text-size-8;
}
::v-deep(h3) {
  @apply text-size-7;
}
::v-deep(h4) {
  @apply text-size-6;
}

::v-deep(.lineClamp) {
  @apply overflow-hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: unset;

  &--active {
    @apply h-[5rem];
    -webkit-line-clamp: 2;
  }
}
</style>
