
import Vue from "vue";
import LazyHydrate from "vue-lazy-hydration";
import { PromoBlocks } from "./PromoBlocks.d";
import BaseCarousel from "~/components/UI/carousel/BaseCarousel.vue";
import ExternalLink from "~/components/UI/links/ExternalLink.vue";
import { TrackingEvents } from "~/store/tracking";

export default Vue.extend({
  name: "PromoBlocks",
  components: {
    LazyHydrate,
    BaseCarousel,
    ExternalLink,
  },
  props: {
    data: {
      type: Object as () => PromoBlocks,
      required: true,
    },
  },
  data() {
    return {
      carouselSettings: {
        showDots: true,
        autoplayDelay: 5,
        slidesPerPage: 1,
        paginateBySlide: true,
        loop: true,
      },
    };
  },
  methods: {
    promoTracking(href: string) {
      this.$store.commit("tracking/addTrackingEvent", {
        type: TrackingEvents.PromoClick,
        data: href,
      });
    },
  },
});
