
import Vue, { PropType } from "vue";
import BaseCarousel from "../BaseCarousel.vue";
import ContentItem, {
  CarouselItem,
} from "~/components/UI/carousel/ContentCarousel/ContentItem.vue";
import { ImageHeight } from "~/components/shared/responsive-image/ResponsiveImage.vue";

export interface SlidesPerPage {
  desktop: number;
  tablet: number;
  mobile: number;
}

export default Vue.extend({
  name: "ContentCarousel",
  components: { BaseCarousel, ContentItem },
  props: {
    items: {
      type: Array as PropType<CarouselItem[]>,
      required: true,
    },
    showReadMore: {
      type: Boolean as PropType<Boolean>,
      required: false,
      default: false,
    },
    slidesPerPage: {
      type: Object as PropType<SlidesPerPage>,
      required: false,
      default: () => ({ desktop: 3, tablet: 2, mobile: 1 }),
    },
    imageHeight: {
      type: Object as PropType<ImageHeight>,
      required: false,
      default: () => {},
    },
    contentColour: {
      type: String as PropType<String>,
      required: false,
      default: "#1a202c",
    },
    bodyColour: {
      type: String as PropType<String>,
      required: false,
      default: "#252525",
    },
    backgroundColour: {
      type: String as PropType<String>,
      required: false,
      default: "",
    },
    borderColour: {
      type: String as PropType<String>,
      required: false,
      default: "",
    },
    ctaBtnColour: {
      type: String as PropType<String>,
      required: false,
      default: "",
    },
    centerItems: {
      type: Boolean as PropType<Boolean>,
      required: false,
      default: false,
    },
    imageIsIcon: {
      type: Boolean as PropType<Boolean>,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      carouselSettings: {
        showDots: true,
        showArrows: true,
        autoplayDelay: 6,
        slidesPerPage:
          this.slidesPerPage.desktop > this.items.length
            ? this.items.length
            : this.slidesPerPage.desktop,
        loop: this.items.length > this.slidesPerPage.desktop,
        paginateBySlide: true,
        gutter: "3rem",
        responsive: [
          {
            maxWidth: 1023,
            slidesPerPage: this.slidesPerPage.tablet,
          },
          {
            maxWidth: 480,
            slidesPerPage: this.slidesPerPage.mobile,
            gutter: "2rem",
          },
        ],
      },
    };
  },
});
