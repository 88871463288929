<template>
  <div
    class="w-full relative py-16 sm:py-23 font-gilroy"
    :class="wrapperClasses?.classes"
    :style="wrapperClasses?.styles"
  >
    <div
      class="content-container"
      :class="innerWrapperClasses?.classes"
      :style="innerWrapperClasses?.styles"
    >
      <MultiTextAndImage
        :data="data"
        :style-type="style"
        :height="height"
        :items-per-row-desktop="itemsPerRowDesktop"
        :items-per-row-tablet="itemsPerRowTablet"
        :items-per-row-mobile="itemsPerRowMobile"
        :is-carousel="isCarousel"
        :cta-btn-colour="ctaBtnColour"
        :title-text-colour="titleTextColour"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { getContainerItemContent } from "@bloomreach/spa-sdk";
import MultiTextAndImage from "~/components/multi-text-and-image/MultiTextAndImage.vue";
import { globalComponentProperties } from "~/composables/globalComponentProperties";

const props = defineProps({
  component: {
    type: Object,
    required: true,
  },
  page: {
    type: Object,
    required: true,
  },
});

// Return component properties from bloomreach SDK
const properties = computed(() => props.component.getParameters());

// Return component data fields from bloomreach SDK
const data = computed(
  () =>
    getContainerItemContent(props.component, props.page)?.titleAndImageItem ??
    null
);

// Deconstruct computed style properties from composable
const { wrapperClasses, innerWrapperClasses, ctaBtnColour, titleTextColour } =
  globalComponentProperties(properties, props.page);

const style = computed(() => {
  return properties.value.style;
});

const height = computed(() => {
  return properties.value.height;
});

const isCarousel = computed(() => {
  return properties.value.isCarousel;
});

const itemsPerRowDesktop = computed(() => {
  return Number(properties.value.itemsPerRowDesktop);
});
const itemsPerRowTablet = computed(() => {
  return Number(properties.value.itemsPerRowTablet);
});
const itemsPerRowMobile = computed(() => {
  return Number(properties.value.itemsPerRowMobile);
});
</script>

<style lang="postcss" scoped></style>
