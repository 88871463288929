<template>
  <div class="w-full relative" :style="backgroundColour">
    <ResponsiveImage
      class="hidden sm:block"
      :images="getDeviceImages(data?.backgroundImage)"
      :alt-tag="title"
      classes="object-cover absolute w-full h-full left-0 top-0"
    />

    <div
      class="content-container h-full p-0 flex"
      :class="contentAlignment?.parent"
    >
      <div
        class="relative h-full w-full sm:w-auto sm:min-w-[450px] max-w-[600px] p-8 md:p-14 flex flex-col gap-8 font-gilroy items-start"
        :class="contentAlignment?.children"
        :style="innerBackgroundColour"
      >
        <img
          v-if="logoUrl"
          :src="logoUrl"
          alt="logo"
          class="w-[150px] md:w-[250px]"
          loading="lazy"
        />
        <div
          ref="carouselTitle"
          class="font-bold text-size-8 md:text-size-10 !leading-[3.5rem] md:!leading-[5.5rem] w-full"
          :style="textColour"
          v-html="title"
        />
        <ul class="flex text-white gap-3 md:gap-6 font-bold flex-wrap">
          <li
            v-for="(item, index) in list"
            :key="index"
            class="w-full md:w-auto"
            :style="textColour"
          >
            <div class="flex gap-2 items-center">
              <div class="w-[8px] h-[8px] rounded-full" :style="bulletColour" />
              {{ item }}
            </div>
          </li>
        </ul>
        <DefaultButton
          v-if="cta.linkText !== ''"
          appearance="pill"
          :path="cta.path"
          class="text-center w-full sm:w-auto sm:max-w-[70%]"
          :style="ctaBtnColour"
        >
          {{ cta.linkText }}
        </DefaultButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import DefaultButton from "~/components/UI/button/DefaultButton.vue";
import ResponsiveImage from "~/components/shared/responsive-image/ResponsiveImage.vue";
import { getDeviceImages } from "~/utils/bloomreach/images";

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  colours: {
    type: Object,
    required: false,
    default: () => {},
  },
  backgroundColour: {
    type: String,
    required: false,
    default: "",
  },
  innerBackgroundColour: {
    type: String,
    required: false,
    default: "",
  },
  contentAlignment: {
    type: Object,
    required: false,
    default: () => {},
  },
  ctaBtnColour: {
    type: String,
    required: false,
    default: "",
  },
  textColour: {
    type: String,
    required: false,
    default: "",
  },
  bulletColour: {
    type: String,
    required: false,
    default: "",
  },
});

const carouselTitle = ref(null);

onMounted(() => {
  addBoldStyles();
});

function addBoldStyles() {
  if (props.colours.secondaryColour === "") return;

  const boldElements = ref(carouselTitle).value.getElementsByTagName("strong");

  for (const el of boldElements) {
    el.style.color = props.colours.secondaryColour;
    el.style.fontWeight = 700;
  }
}

const title = computed(() => {
  return props.data?.html.value;
});

const logoUrl = computed(() => {
  return props.data?.logoUrl;
});

const list = computed(() => {
  return props.data?.list?.item ?? [];
});

const cta = computed(() => {
  return {
    path: props.data?.cta?.externalLink ?? "",
    linkText: props.data?.cta?.linkText ?? "",
  };
});
</script>

<style lang="postcss" scoped></style>
