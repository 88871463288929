<template>
  <BaseCarousel :settings="carouselSetting">
    <template #slides>
      <div v-for="(slide, index) in data" :key="index">
        <div class="flex flex-row gap-6 h-full">
          <component
            :is="getSlideType(slide.style)"
            v-for="(slideItem, subIndex) in slide.hero"
            :key="subIndex"
            :data="slideItem"
            :colours="{ primaryColour, secondaryColour }"
            :class="imageHeight"
            :background-colour="bgColour"
            :inner-background-colour="innerBgColour"
            :content-alignment="contentAlignment"
            :cta-btn-colour="ctaBtnColour"
            :text-colour="bodyTextColour"
            :bullet-colour="bulletColour"
          />
        </div>
      </div>
    </template>
  </BaseCarousel>
</template>

<script setup>
import { getContainerItemContent } from "@bloomreach/spa-sdk";
import { computed } from "vue";
import { globalComponentProperties } from "~/composables/globalComponentProperties";
import BaseCarousel from "~/components/UI/carousel/BaseCarousel.vue";

// Slide Variants
import VersionOne from "~/components/bloomreach/hero-banner/variants/versionOne.vue";
import VersionTwo from "~/components/bloomreach/hero-banner/variants/versionTwo.vue";

const props = defineProps({
  component: {
    type: Object,
    required: true,
  },
  page: {
    type: Object,
    required: true,
  },
});

const { primaryColour, secondaryColour } = props.page.getDocument().model.data;

// Return component properties from bloomreach SDK
const properties = computed(() => props.component.getParameters());

// Return component data fields from bloomreach SDK
const data = computed(
  () =>
    getContainerItemContent(props.component, props.page)?.heroBannerItem ?? null
);

function getSlideType(type) {
  switch (type?.selectionValues[0].key) {
    case "version 1":
      return VersionOne;
    case "version 2":
      return VersionTwo;
    default:
      return VersionOne;
  }
}

const carouselSetting = {
  showDots: false,
  autoplayDelay: 0,
  slidesPerPage: 1,
  paginateBySlide: true,
  showArrows: true,
  loop: true,
  responsive: [
    {
      maxWidth: 640,
      slidesPerPage: 1,
    },
  ],
};

const selectedHeight = computed(() => properties.value?.height);

// Deconstruct computed style properties from composable
const {
  height,
  bgColour,
  innerBgColour,
  contentAlignment,
  ctaBtnColour,
  bodyTextColour,
  bulletColour,
} = globalComponentProperties(properties, props.page);

const imageHeight = computed(() => {
  if (selectedHeight.value === "large")
    return "min-h-[350px] md:min-h-[250px] lg:min-h-[500px]";
  if (selectedHeight.value === "medium")
    return "min-h-[350px] md:min-h-[250px] lg:min-h-[300px]";
  if (selectedHeight.value === "small") return "min-h-[350px] md:min-h-[250px]";
  return height.value;
});
</script>
