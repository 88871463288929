
import Vue from "vue";
import { mapGetters } from "vuex";
import { prefixCategory } from "~/lib/shared";
import type {
  TaxonomyItem,
  Department,
} from "~/components/layout/header/components/Departments";
import { TaxonomyDepartmentIDs } from "~/components/taxonomy/attributes";
import { ChevronIcon } from "~/components/UI/icons";

export default Vue.extend({
  name: "DepartmentsNavigation",
  components: {
    ChevronIcon,
  },
  data(): {
    departmentsHierarchy: Department[];
  } {
    return {
      departmentsHierarchy: [],
    };
  },
  async fetch() {
    (this as any).getDepartmentsHierarchy ??
      (await this.$store.dispatch("locale/fetchDepartmentsHierarchy"));

    this.departmentsHierarchy = (this as any).getDepartmentsHierarchy;
  },
  computed: {
    ...mapGetters("locale", ["getDepartmentsHierarchy"]),

    newCatId() {
      return TaxonomyDepartmentIDs.New;
    },
  },
  methods: {
    generateUrl({ slug, id }: TaxonomyItem) {
      return `/${slug}/${prefixCategory(id)}`;
    },
  },
});
