<template>
  <div>
    <BrComponent component="base/header">
      <BrComponent component="header/top-navigation-menu" />
    </BrComponent>
    <div class="content-container py-8">
      <BrComponent component="top-row/container" />
      <div class="flex flex-col gap-6 mt-8">
        <div class="flex gap-6">
          <BrComponent component="left-column/container" class="w-1/4" />
          <BrComponent component="right-column/container" class="w-3/4" />
        </div>
        <BrComponent component="bottom-row/container" class="w-full" />
      </div>
    </div>
  </div>
</template>
<script>
import { BrComponent } from "@bloomreach/vue-sdk";

export default {
  name: "TopLevelCategory",
  components: { BrComponent },
};
</script>

<style lang="postcss" scoped></style>
