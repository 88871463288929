
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "BodyText",
  props: {
    text: {
      type: String as PropType<string>,
      required: true,
    },
    readMore: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    readMoreClasses(): Array<string> {
      return [
        !this.readMore || this.isOpen
          ? "truncate-text"
          : " truncate-text truncate-text--active",
      ];
    },
  },
  methods: {
    toggleState(): void {
      this.isOpen = !this.isOpen;
    },
  },
});
